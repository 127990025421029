<template>
    <v-row justify="center">
        <v-dialog v-model="detalleOpen" persistent transition="scale-transition" max-width="1400px">
            <v-card color="basil">
                <v-card-title class="text-center justify-center py-2">
                    <v-col cols=1>
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon size="40" class="mr-10" color="primary">fas fa-times-circle</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols=11>
                        <h1 class="font-weight-bold text-h4 basil--text">
                            VENTA: {{ detailsVenta.ticketFolio }}
                        </h1>
                    </v-col>

                </v-card-title>
                <!-- <v-toolbar dark text="white" color="primary">
                    <v-toolbar-title>
                        <h4>DETALLE VENTA {{ detailsVenta.ticketFolio }}</h4>
                    </v-toolbar-title>
                </v-toolbar> -->
                <v-tabs background-color="blue-grey lighten-4" color="teal darken-3" grow v-model="tab"
                class="text-h6 text-white">
                    <v-tab v-for="item in filterTabs" :key="item.value">
                        <h4>{{ item.text }}</h4>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item tab="venta">
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.ticketFolio" label="Ticket de venta" readonly
                                        text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.registerDate" label="Fecha de salida" readonly
                                        text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.tipoSalida.nombre" label="Tipo de salida"
                                        readonly text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-0">
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.cliente?.nombreComercial"
                                        label="Nombre del cliente" solo-inverted readonly
                                        class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.tipoPago.formaPago" label="Forma de Pago"
                                        solo-inverted readonly class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailsVenta.tipoMoneda.moneda" label="Tipo de Moneda"
                                        solo-inverted readonly class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-simple-table dense dark border="left" fixed-header>
                                        <template v-slot:default>
                                            <thead>
                                                <tr class="font-weight-bold">
                                                    <th class="text-left" scope="producto">Producto</th>
                                                    <th class="text-left" scope="unidad">Unidad</th>
                                                    <th class="text-left" scope="cantidad">Cantidad</th>
                                                    <th class="text-left" scope="kg">KG</th>
                                                    <th class="text-left" scope="precio">Precio(kg)</th>
                                                    <th class="text-left" scope="total">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in itemVenta" :key="item.id">
                                                    <td v-text="item.producto.nombre"></td>
                                                    <td v-text="item.unidadSalida.nombre"></td>
                                                    <td v-text="item.cantidad"></td>
                                                    <td v-text="item.kg"></td>
                                                    <td v-text="'$ ' + item.precioVenta"></td>
                                                    <td v-text="'$ ' + item.total"></td>
                                                </tr>
                                                <tr class="font-weight-black">
                                                    <td colspan="3"></td>
                                                    <td>Subtotal:</td>
                                                    <td v-text="totalVentaNatural"></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-list-item class="grow">
                                <v-row align="center" justify="end">
                                    <v-tooltip left color="success">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="ma-2" color="primary" v-bind="attrs" v-on="on" dark
                                                @click.prevent="printTicket(detailsVenta.id)">
                                                <v-icon dark left>fas fa-file-pdf</v-icon>Ver ticket
                                            </v-btn>
                                        </template>
                                        <span>Descargar detalle de venta</span>
                                    </v-tooltip>
                                    <v-tooltip left color="success" v-if="isSale">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="ma-2" color="blue darken-4" v-bind="attrs" v-on="on" dark
                                                @click.prevent="printTicketWithAPrinter(detailsVenta.id)">
                                                <v-icon dark left>fas fa-print</v-icon>Imprimir ticket
                                            </v-btn>
                                        </template>
                                        <span>Imprimir ticket</span>
                                    </v-tooltip>
                                </v-row>
                            </v-list-item>
                        </v-card-actions>
                    </v-tab-item>
                    <v-tab-item tab="pagos">
                        <DetailTablePayment :payments="payments" :detailsVenta="detailsVenta" :venta-id="detailsVenta.id" />
                    </v-tab-item>
                </v-tabs-items>

                <v-card-actions>
                    <v-list-item class="grow">
                        <v-row justify="start" align="center">
                            <v-btn color="primary" dark @click="handleModal">
                                <v-icon dark left>fas fa-cancel</v-icon>Cerrar detalle
                            </v-btn>
                        </v-row>
                    </v-list-item>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
            :title-pdf="'VENTA ' + detailsVenta.ticketFolio"
            :handlePdfModal="handlePdfModal"
            :dialog="showPdf" v-if="showPdf">
        </PreviewPDF>

        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
            :title-pdf="'TICKET ' + detailsVenta.ticketFolio"
            :handlePdfModal="handlePdfModalTicket"
            :dialog="showPdfTicket" v-if="showPdfTicket">
        </PreviewPDF>

    </v-row>
</template>
<script>
import VentaItemDataService from '@/service/venta/VentaDetalleDataService';
import VentaPagoDetalleDataService from '@/service/venta/pago/VentaPagoDetalleDataService';
import DetailTablePayment from './pago/DetailTablePayment.vue';
import { getFormatCurrency } from '@/utils/CurrencyUtil';
import VentaDataService from '@/service/venta/VentaDataService';
import PreviewPDF from '@/components/shared/PreviewPDF.vue';

export default {
    name: "DetalleSale",
    props: {
        detalleOpen: { type: Boolean, default: false },
        handleModal: { type: Function },
        detailsVenta: { type: Object, default: () => ({}) },
    },
    components: { DetailTablePayment, PreviewPDF },
    created() {
        this.detalleItemVenta();
        this.getPagos();
    },
    data() {
        return {
            itemVenta: [],
            payments: [],
            tab: 'venta',
            itemTabs: [
                { text: 'DETALLE DE VENTA', value: 'venta' },
                { text: 'PAGOS', value: 'pagos' }
            ],
            // to PDF
            pdfBlob: '',
            pdfSource: null,
            showPdf: false,
            showPdfTicket: false
        }
    },
    methods: {
        detalleItemVenta() {
            VentaItemDataService.showDetailsVenta(this.detailsVenta.id).then(resp => {
                this.itemVenta = resp.data;
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener los datos', html: err.response.data, showConfirmButton: false, timer: 1500 });
            });
        },
        getPagos(){
            VentaPagoDetalleDataService.findByVenta(this.detailsVenta.id).then(resp => {
                this.payments = resp.data;
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener los pagos', html: err.response.data, showConfirmButton: false, timer: 1500 });
            })
        },
        // downloadPdf() {
        //     const blobPdf = generatePDFVenta(this.detailsVenta, this.itemVenta);
        //     window.open(blobPdf, '_blank');
        //     window.URL.revokeObjectURL(blobPdf);
        // },
        printTicket(ventaId) {
            VentaDataService.printTicket(ventaId).then( response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        },
        printTicketWithAPrinter(ventaId) {
            VentaDataService.printTicketWithAPrinter(ventaId).then( response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdfTicket = true
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        },
        handlePdfModal() {
            this.showPdf = false
            this.cleanupPdf()
        },
        handlePdfModalTicket() {
            this.showPdfTicket = false
            this.cleanupPdf()
        },
        cleanupPdf() {
            if (this.pdfSource) {
                URL.revokeObjectURL(this.pdfSource);
                this.pdfSource = null;
            }
            this.pdfBlob = null;
        }
    },
    computed: {
        isSale(){ return this.detailsVenta.tipoSalida.nombre == 'Venta' || this.detailsVenta.tipoSalida.nombre == "Venta interna"; },
        isCredit(){ return this.detailsVenta.tipoPago.id == 2; },
        filterTabs() {
            if(this.isSale){
                return this.itemTabs;
            } else {
                return this.itemTabs.filter(item => item.value != 'pagos');
            }
        },
        totalVentaNatural() {
            return getFormatCurrency(this.detailsVenta.total, '$ ');
        },
    }
}
</script>
<style>
    .basil {
        background-color: #d2d6c1 !important;
    }
    .basil--text {
        color: #0d8b65 !important;
    }
</style>