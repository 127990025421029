<template>
    <v-row align="center" justify="center">
        <v-col cols="12" md="12" sm="12" class="mb-0">
            <v-breadcrumbs :items="pestanias" class="m-0">
                <template v-slot:divider>
                    <v-icon>fas fa-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <!-- <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start" title="Fecha inicio" /> -->
                    <!-- <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" /> -->
                    <v-divider class="mx-2" inset vertical></v-divider>
                    <v-col cols="12" md="12" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                            label="Buscar registros por folio" single-inline hide-details
                            @keydown.enter="searchVenta">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark
                                    @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte de pagos</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table :headers="filteredHeaders" :items="ventas" class="elevation-2" :loading="loading" dense
                    loading-text="Cargando... espera un momento" :options.sync="options" :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ],
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                }" :server-items-length="totalVentas">
                    <template v-slot:[`item.actions`] ="{ item }">
                        <ActionCompra :item="item" :showDetailVenta="showDetailVenta"
                        :show-credit-note="showCreditNote"/>
                    </template>
                    <template v-slot:[`item.ventaEstatus`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="item.colorEstatus"
                                small dense filled label v-if="item.ventaEstatus != null "> {{ item.ventaEstatus }}
                            </v-chip>
                        </template>
                </v-data-table>
            </v-card>
        </v-col>
        <DetalleSale :detalleOpen.sync="openDetailSale" :detailsVenta.sync="detailsVenta"
        :handleModal="handleDetalleModal" v-if="openDetailSale"/>
        <CreditNoteSale :openCreditNote.sync="openCreditNote" :detail-sale.sync="detailsVenta"
        :handleModal="handleCreditNoteModal"
        v-if="openCreditNote" />

        <ExcelExporterPago :dialog.sync="dialogExcel" :handleModal="handleExcelExport" :client="client" v-if="dialogExcel"/>
    </v-row>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import VentaDataService from '@/service/venta/VentaDataService';
import { getFirstDayOfCurrentMonth, getToday } from '@/utils/DateUtil';
import { getParamsPagination } from '@/utils/pagination';
import DetalleSale from '@/views/venta/DetalleSale.vue';
import ActionCompra from './ActionCompra.vue';
import ExcelExporterPago from '@/views/venta/pago/ExcelExporterPago.vue';
import clienteDataService from '@/service/cliente/clienteDataService';
import Columns from '@/components/shared/Columns.vue';
import CreditNoteSale from '@/views/venta/creditNote/CreditNoteSale.vue';

export default {
    name: "ListaCompras",
    props: {
        clientId: { type: Number, required: true }
    },
    components: { SingleCalendar, DetalleSale, CreditNoteSale, ActionCompra, ExcelExporterPago, Columns },
    created() { this.getDataClient(); },
    data() {
        return {
            ventas: [],
            datesRange: {
                start: getFirstDayOfCurrentMonth().toFormat('yyyy-MM-dd'),
                end: getToday().toFormat('yyyy-MM-dd')
            },
            options: {},
            totalVentas: 0,
            loading: true,
            search: '',
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "idVenta", visible: false, groupable: false, show: false },
                { text: 'Sucursal salida', value: 'sucursalEnvia.nombre', groupable: false, show: true },
                { text: "Folio", value: "ticketFolio", groupable: false, show: true },
                { text: 'Fecha salida', value: 'registerDate', groupable: false, show: true },
                { text: 'Tipo salida', value: 'tipoSalida.nombre', groupable: false, show: true },
                { text: "Cliente", value: "cliente.nombreComercial", groupable: false, show: true },
                { text: "Forma Pago", value: "tipoPago.formaPago", groupable: false, show: true },
                { text: "Total", value: "totalNatural", groupable: false, show: true },
                { text: "Saldo", value: "saldoNatural", groupable: false, show: true },
                { text: 'Estatus', value: 'ventaEstatus', groupable: false, show: true },
                { text: "Abonado", value: "abonadoNatural", groupable: false, show: true },
            ],
            pestanias: [
                { text: 'Inicio', disabled: false, to: '/controlPanel' },
                { text: 'Clientes', disabled: false, to: '/controlPanel/clientes' },
            ],
            client: {},
            dialogExcel: false,
            // TO OPEN DETAIL SALE
            openDetailSale: false,
            // TO OPEN CREDIT NOTE
            openCreditNote: false,
        }
    },
    methods: {
        searchVenta() {
            this.options.page = 1;
            this.initSellsClient(this.options);
        },
        initSellsClient(options = {}) {
            this.loading = true;
            const params =  getParamsPagination({ options, search: this.search });
            params.clientId = this.clientId;
            VentaDataService.table(params).then(resp => {
                const { rows, totalElements } = resp.data;
                this.ventas = rows;
                this.totalVentas = totalElements;
            }).catch( error => {
                this.$swal.fire({ title: 'Error', html: error.response.data, icon: 'error', showConfirmButton: false, timer: 3500 });
            }).finally(() => {
                this.loading = false;
            })
        },
        showDetailVenta( item ){
            this.detailsVenta = item;
            this.openDetailSale = true;
        },
        showCreditNote( item ){
            this.detailsVenta = item;
            this.openCreditNote = true;
        },
        handleExcelExport() { this.dialogExcel = !this.dialogExcel; },
        handleDetalleModal() { this.openDetailSale = !this.openDetailSale; },
        handleCreditNoteModal() { 
            this.openCreditNote = !this.openCreditNote;
        },
        async getDataClient() {
            const response = await clienteDataService.show(this.clientId);
            this.client = response.data;
            this.pestanias.push({ text: `Compras de: ${this.client.nombreComercial}`, disabled: true });
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initSellsClient(val);
                }
            }, deep: true
        },
        'datesRange.start': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initSellsClient(this.options);
            }
        },
        'datesRange.end': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initSellsClient(this.options);
            }
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>