<template>
    <v-row justify="center">
        <v-dialog v-model="openCreditNote" persistent transition="scale-transition" max-width="1400px">
            <v-card color="grey lighten-4">
                <v-card-title class="text-left py-2">
                    <v-col cols=12 class="d-flex align-center justify-space-between">
                        <h6 class="font-weight-bold text-h6 primary--text">
                            NOTA DE CREDITO: {{ detailSale.ticketFolio }}
                        </h6>
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon size="40" class="mr-10" color="primary">fas fa-times-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-tabs background-color="blue-grey lighten-4" color="teal darken-3" grow v-model="tab"
                class="text-h6 text-white">
                    <v-tab v-for="item in filterTabs" :key="item.value">
                        <h4>{{ item.text }}</h4>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item tab="venta">
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col cols="12" sm="4" md="3">
                                    <v-text-field :value="detailSale.ticketFolio" label="Ticket de venta" readonly
                                        text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="3">
                                    <v-text-field :value="detailSale.registerDate" label="Fecha de salida" readonly
                                        text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :value="detailSale.cliente?.nombreComercial"
                                        label="Nombre del cliente" readonly text
                                        class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="2">
                                    <v-text-field :value="detailSale.tipoPago.formaPago" label="Forma de Pago"
                                    readonly text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="3">
                                    <v-text-field :value="detailSale.totalNatural" label="Total"
                                    readonly text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="3">
                                    <v-text-field :value="detailSale.abonadoNatural" label="Abonado"
                                    readonly text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="3">
                                    <v-text-field :value="detailSale.saldoNatural" label="Saldo"
                                    readonly text class="d-flex flex-row-reverse">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-simple-table dense fixed-header class="elevation-1 shadow rounded">
                                        <template v-slot:default>
                                            <thead>
                                                <tr class="font-weight-bold">
                                                    <th class="text-left" scope="producto">Producto</th>
                                                    <th class="text-left" scope="unidad">UM Salida</th>
                                                    <th class="text-left" scope="cantidad">Cantidad</th>
                                                    <th class="text-left" scope="kg">KG</th>
                                                    <th class="text-left" scope="precio">Precio(KG)</th>
                                                    <th class="text-left" scope="total">Total</th>
                                                    <th class="text-left" scope="quantityAvailable">Cant. dispo</th>
                                                    <th class="text-left" scope="typeDiscount">Descuento</th>
                                                    <th class="text-left" scope="quantity">Cantidad</th>
                                                    <th class="text-left" scope="cost">Costo</th>
                                                    <th class="text-left" scope="totalCost">Total</th>
                                                    <th class="text-left" scope="difference">Diferencia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in creditNoteDetails" :key="item.id">
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.producto.nombre"></td>
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.unidadSalida.nombre"></td>
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.cantidad"></td>
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.kg"></td>
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.precioVenta"></td>
                                                    <td class="teal lighten-4 font-weight-bold" v-text="item.total"></td>
                                                    <td>
                                                        <v-chip class="ma-2" color="pink" label text-color="white" medium>
                                                            <v-icon left> fas fa-tags</v-icon> {{ item.quantityAvailable }}
                                                        </v-chip>
                                                    </td>
                                                    <td>
                                                        <v-switch v-model="item.typeDiscount" color="warning" label="Precio" value="PRICE"
                                                        @change="(e) => changeItem(e, index, 'typeDiscount')">
                                                        </v-switch>
                                                    </td>
                                                    <td>
                                                        <v-text-field v-model="item.quantity" type="number" solo dense label="Cant."
                                                        class="font-weight-medium text-h6 mt-2 green--text" persistent-hint
                                                        :max="item.quantityAvailable" @input="(e) => changeItem(e, index, 'quantity')">
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field v-model="item.cost" type="number" solo dense label="Costo"
                                                        class="font-weight-medium text-h6 mt-2 green--text" persistent-hint
                                                        :max="item.precioVenta"
                                                        @input="(e) => changeItem(e, index, 'cost')" :disabled="item.typeDiscount == 'PRICE'">
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field :value="item.totalCost" type="number" solo dense
                                                        class="font-weight-medium text-h6 mt-2 green--text" persistent-hint prefix="$" disabled>
                                                        </v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-text-field :value="item.difference" type="number" solo dense
                                                        class="font-weight-medium text-h6 mt-2 green--text" persistent-hint prefix="$" disabled>
                                                        </v-text-field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colspan="6" class="text-left"></th>
                                                    <th colspan="4" class="green darken-4 text-left white--text text-h5">Total notas de credito:</th>
                                                    <th colspan="3" class="green darken-4 text-left white--text text-h5">
                                                        $ {{ creditNote.amount }}
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" class="white--text" @click.prevent="handleModal">
                                <v-icon left>fas fa-times-circle</v-icon> Cerrar ventana
                            </v-btn>
                            <v-btn color="blue darken-1" class="white--text" @click.prevent="saveCreditNote" v-if="hasCreditNote">
                                <v-icon left>fas fa-save</v-icon> Generar nota de credito
                            </v-btn>
                        </v-card-actions>
                    </v-tab-item>
                    <v-tab-item tab="creditNotes">
                        <DetailCreditNote
                        :cancelCreditNote="cancelCreditNote" :creditNotes="creditNotes"
                        :detail-sale="detailSale" :getDetailSale="getDetailSale"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import CreditNoteDataService from '@/service/venta/creditNote/CreditNoteDataService';
import VentaItemDataService from '@/service/venta/VentaDetalleDataService';
import DetailCreditNote from './DetailCreditNote.vue';

export default {
    name: "CreditNoteSale",
    props: {
        openCreditNote: { type: Boolean },
        detailSale: { type: Object },
        handleModal: { type: Function },
        initSellsClient: { type: Function },
    },
    components: { DetailCreditNote },
    created() {
        this.getDetailSale()
    },
    data() {
        return {
            creditNotes: [],
            creditNote: {
                id: null,
                ventaId: null,
                amount: 0,
                status: 'ACTIVE',
            },
            creditNoteDetails: [],
            tab: 'venta',
            itemTabs: [
                { text: 'DETALLE DE VENTA', value: 'venta' },
                { text: 'NOTAS DE CREDITO', value: 'creditNotes' }
            ],
        }
    },
    methods: {
        getTotalCredit( creditNotes){
            if(creditNotes.length > 0){
                this.itemTabs[1].text = `NOTAS DE CREDITO (${creditNotes.length})`;
                return
            }
            this.itemTabs.splice(1, 1);
        },
        getCreditNotesWithoutCancelAndAmount(sale, creditNotes){
            return sale.map( item => {
                const relevantProducts = creditNotes.flatMap(note => {
                    const { creditNote, creditNoteDetails } = note;
                    return creditNoteDetails.filter(prod =>  prod.productoId === item.productoId && prod.typeDiscount === 'QUANTITY' && creditNote.status !== 'CANCELLED' );
                });
                const totalAccumulatedQuantity = relevantProducts.reduce((total, prod) => total + prod.quantity, 0);
                const measure = item.unidadSalida.id;
                const quantityAvailable = (measure === 1) ? (item.cantidad - totalAccumulatedQuantity) : (item.kg - totalAccumulatedQuantity);

                return {
                    ...item,
                    quantityAvailable,
                    typeDiscount: 'QUANTITY',
                    quantity: 0,
                    cost: 0,
                    totalCost: 0,
                    difference: 0
                }
            });
        },
        getDetailSale() {
            CreditNoteDataService.findCreditNote(this.detailSale.id).then( result => {
                return result
            }).then((result) => {
                this.creditNotes = result.data
                this.getTotalCredit(this.creditNotes)
                VentaItemDataService.showDetailsVenta(this.detailSale.id).then(response => {
                    const sale = response.data
                    this.creditNote.ventaId = this.detailSale.id
                    this.creditNote.sucursalId = this.detailSale.sucursalEnvia.id;
                    this.creditNoteDetails = this.getCreditNotesWithoutCancelAndAmount(sale, this.creditNotes)
                }).catch(err => {
                    this.$swal.fire({ icon: 'error', title: 'Error al obtener los datos', html: err.response.data, showConfirmButton: false, timer: 1500 });
                });
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener los datos', html: err.response.data, showConfirmButton: false, timer: 1500 });
            })
        },
        changeItem(e, index, input) {
            const product = this.creditNoteDetails[index];
            if(input == 'typeDiscount') {
                const typeDiscount = e != null ? e : 'QUANTITY';
                product.typeDiscount = typeDiscount
                if(typeDiscount === 'PRICE') {
                    product.cost = product.precioVenta
                    product.totalCost = product.precioVenta * product.quantity
                    product.difference = product.total - product.totalCost
                }
            }
            if(input == 'quantity' || input == 'cost') {
                const value = isNaN(parseInt(e)) ? 0 : parseInt(e);
                product[input] = value;
                if(value > 0) {
                    product.totalCost = product.cost * product.quantity
                    product.difference = product.total - product.totalCost
                }
            }
            this.creditNoteDetails[index] = product;
            const total = this.creditNoteDetails.reduce( (acm, item) => acm + item.totalCost, 0);
            this.creditNote.amount = total;
        },
        saveCreditNote(){
            const creditNote = {
                'creditNote': this.creditNote,
                'creditNoteDetails': this.creditNoteDetails
            }
            if(creditNote.amount > this.detailSale.saldo){
                this.$swal.fire({ icon: 'error', title: 'Error al crear la nota de crédito', html: 'El monto de la nota de crédito es mayor al saldo de la venta', showConfirmButton: false, timer: 1500 });
                return
            }
            this.$swal.fire({
                title: "Estas seguro que deseas crear esta nota de crédito?",
                text: "Esta accion no se podra revertir!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, crear nota!",
                cancelButtonText: "No"
            }).then( result => {
                if (result.isConfirmed) {
                    CreditNoteDataService.create(creditNote).then( result => {
                        this.$swal.fire({ icon: 'success', title: 'Nota de crédito creada', showConfirmButton: false, timer: 1500 });
                        this.handleModal()
                        this.getDetailSale()
                    }).catch(err => {
                        this.$swal.fire({ icon: 'error', title: 'Error al crear la nota de crédito', html: err.response.data, showConfirmButton: false, timer: 1500 });
                    })
                }
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Error al crear la nota de crédito', html: err.response.data, showConfirmButton: false, timer: 1500 });
            })
        },
        cancelCreditNote(creditNoteId){
            this.$swal({
                title: "Estas seguro que deseas cancelar esta nota de crédito?",
                text: "Esta accion no se podra revertir!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, cancelar nota!",
                cancelButtonText: "No"
            }).then( result => {
                if (result.isConfirmed) {
                    CreditNoteDataService.cancel(creditNoteId).then(result => {
                        this.$swal.fire({ icon: 'success', title: 'Nota de crédito cancelada', showConfirmButton: false, timer: 1500 })
                        this.getDetailSale()
                        this.initSellsClient()
                    }).catch(err => {
                        this.$swal.fire({ icon: 'error', title: 'Error al cancelar la nota de crédito', html: err.response.data, showConfirmButton: false, timer: 1500 })
                    });
                }
            });
        }
    },
    computed: {
        isSale(){ return this.detailSale.tipoSalida.nombre == 'Venta'; },
        isCredit(){ return this.detailSale.tipoPago.id == 2; },
        filterTabs() {
            if(this.isSale){
                return this.itemTabs;
            } else {
                return this.itemTabs.filter(item => item.value !== 'creditNotes');
            }
        },
        totalCreditNote(){ return this.creditNotes.length },
        hasCreditNote(){ return this.creditNote.amount > 0 && this.detailSale.saldo > 0; },
    }

}
</script>