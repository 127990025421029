<template>
    <v-menu bottom offset-x rounded="lg" transition="slide-y-transition" color="primary">
       <template v-slot:activator="{ on, attrs }">
           <v-btn icon v-bind="attrs" v-on="on" color="teal darken-1" class="white--text ma-5">
               <v-icon>fas fa-ellipsis-v</v-icon>
           </v-btn>
       </template>
       <v-list>
           <v-list-item link @click.prevent="showDetailVenta(item)">
               <v-list-item-title>
                   <v-icon small class="mr-2">fas fa-chart-line</v-icon> Detalle venta
               </v-list-item-title>
           </v-list-item>
           <v-list-item link @click.prevent="showCreditNote(item)" v-if="isCredit">
               <v-list-item-title>
                   <v-icon small class="mr-2">fas fa-money-bill-wave</v-icon> Nota de credito
               </v-list-item-title>
           </v-list-item>
       </v-list>
    </v-menu>
</template>
<script>
export default {
    name: "ActionsCompra",
    props: {
        item: { type: Object },
        showDetailVenta: { type: Function },
        showCreditNote: { type: Function }
    },
    computed: {
        isCredit(){
            return this.item.tipoPago.formaPago === 'Credito' && this.item.ventaEstatus !== 'Cancelada';
        }
    }
}
</script>