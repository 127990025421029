<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-simple-table dense border="left" fixed-header class="elevation-1">
                    <template v-slot:default>
                        <thead>
                            <tr class="font-weight-bold">
                                <th class="text-left" scope="id">ID</th>
                                <th class="text-left" scope="ticketSale">Folio venta</th>
                                <th class="text-left" scope="sucursal">Sucursal</th>
                                <th class="text-left" scope="amount">Monto</th>
                                <th class="text-left" scope="status">Estatus</th>
                                <th class="text-left" scope="actons">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="note in creditNotes" :key="note.id">
                                <td>{{ note.creditNote.id }}</td>
                                <td>{{ note.creditNote.ticketSale }}</td>
                                <td>{{ note.creditNote.sucursal }}</td>
                                <td>{{ note.creditNote.amount }}</td>
                                <td>{{ note.creditNote.status }}</td>
                                <td v-if="note.creditNote.status != 'CANCELLED'">
                                    <v-btn x-small color="primary" text @click="cancelCreditNote(note.creditNote.id)">
                                        <v-icon>fas fa-times</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: 'DetailCreditNote',
    props: {
        cancelCreditNote: { type: Function },
        creditNotes: { type: Array },
        detailSale: { type: Object, default: () => {} },
        getDetailSale: { type: Function },
    }
}
</script>