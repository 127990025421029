
import { http, authHeader } from '@/http-commons';

class CreditNoteDataService {

    create( creditNote ){
        return http.post('api/v1/creditNote/create', creditNote, { headers : authHeader() });
    }
    cancel( creditNoteId ){
        return http.delete(`api/v1/creditNote/cancel/${creditNoteId}`, { headers : authHeader() });
    }
    findCreditNote( saleId ) {
        return http.get(`api/v1/creditNote/findCreditNote/${saleId}`, { headers : authHeader() });
    }
}

export default new CreditNoteDataService()